import DatePicker from 'react-datepicker'
import { useCallback } from 'react'
import PropTypes from 'prop-types'

import { formatDateToYYYYMMDD } from '~/utils/helpers'
import { CalendarMonthRounded } from '@mui/icons-material'
import './DateRangePickerCustom.scss'
import { useDispatch, useSelector } from 'react-redux'
import { selectFormatDateState } from '~/store/formatdate/selector'
import { useTranslation } from 'react-i18next'
import { setFilterObject } from '~/store/filter/slice'

const RangeDateFieldPicker = ({ filterObject, page }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const format = useSelector(selectFormatDateState)

  const onChange = useCallback(
    dates => {
      const [start, end] = dates
      const startDate = start ? formatDateToYYYYMMDD(start) : null
      const endDate = end ? formatDateToYYYYMMDD(end) : null

      dispatch(
        setFilterObject({
          page,
          filter: {
            name: filterObject.label,
            value: [startDate, endDate]
          }
        })
      )
      filterObject.setValue([start, end])
    },
    [filterObject, dispatch, page]
  )

  return (
    <DatePicker
      showIcon
      placeholderText={t('general.searchRange')}
      wrapperClassName='date_picker'
      dateFormat={format}
      selected={filterObject.value[0] ? new Date(filterObject.value[0]) : null}
      onChange={onChange}
      startDate={filterObject.value[0] ? new Date(filterObject.value[0]) : null}
      endDate={filterObject.value[1] ? new Date(filterObject.value[1]) : null}
      selectsRange
      icon={<CalendarMonthRounded />}
    />
  )
}

RangeDateFieldPicker.propTypes = {
  filterObject: PropTypes.object.isRequired,
  page: PropTypes.string
}

export default RangeDateFieldPicker
