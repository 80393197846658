import { useQuery } from 'react-query'
import { getHealthCheckStatuses } from '~/api'

export const useHealthCheckStatusQuery = tenantId => {
  return useQuery(
    ['health_check_statuses', tenantId],
    async () => {
      const { data } = await getHealthCheckStatuses(tenantId)
      return data
    },
    {
      enabled: !!tenantId
    }
  )
}
