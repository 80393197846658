import { createSlice } from '@reduxjs/toolkit'

export const filterSlice = createSlice({
  name: 'filterState',
  initialState: {
    filterObject: {
      page: '',
      filter: []
    }
  },
  reducers: {
    setFilterObject: (state, action) => {
      const { page, filter } = action.payload
      if (page !== state.filterObject.page) {
        state.filterObject = { page, filter: [filter] }
      } else {
        state.filterObject = {
          page,
          filter: [filter, ...state.filterObject.filter]
        }
      }
    }
  }
})

export const { setFilterObject } = filterSlice.actions

export default filterSlice
