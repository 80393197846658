import adminAxiosClient from './adminBase'

class OverviewUserApi {
  overviewUsers = params => adminAxiosClient.get('api/overview/users', { params })
  overviewUsersDetail = id => adminAxiosClient.get(`api/overview/users/${id}`)
  updateDisableUser = (id, payload) => adminAxiosClient.put(`api/overview/users/${id}`, payload)
  getDisabledUsers = params => adminAxiosClient.get('api/overview/users-disable', { params })

  overviewChangeActiveTenant = (id, payload) =>
    adminAxiosClient.put(`api/overview/users/${id}/change-active-tenant`, payload)

  updateRole = (id, payload) =>
    adminAxiosClient.put(`api/overview/users/${id}/update-role`, payload)

  addExtraUserToTenant = payload =>
    adminAxiosClient.post('api/overview/users/invite-extra-user', { ...payload })

  updateSettingUser = payload => adminAxiosClient.put('api/overview/me/update-setting', payload)

  getActivityLogs = params => adminAxiosClient.get('api/overview/activity-logs', { params })
  getActivityLogDetail = id => adminAxiosClient.get(`api/overview/activity-logs/${id}`)
  userLogFilter = params => adminAxiosClient.get('api/overview/activity-logs-by-users', { params })
}

export default new OverviewUserApi()
