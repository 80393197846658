import {
  DASHBOARD,
  DEVICE_LOGS,
  GOOGLE_DEVICES,
  ORGANISATION,
  ORG_OVERVIEW,
  PRE_PROVISIONING_TOKEN
} from '~/constants/Routes'
import {
  DASHBOARD_VIEW,
  DEVICES_VIEW,
  ORGANISATIONS_OVERVIEW,
  ORGANISATIONS_VIEW,
  ZTE_TOKEN_VIEW
} from '~/constants/userPermissions'

export const GOOGLE_MENU_ITEMS = [
  {
    title: 'userMenu.dashboard',
    url: DASHBOARD,
    icon: 'flutter_dash',
    permission: DASHBOARD_VIEW
  },
  {
    title: 'userMenu.devices',
    url: GOOGLE_DEVICES,
    icon: 'devices',
    permission: DEVICES_VIEW,
    children: [
      {
        title: 'userMenu.devices',
        url: GOOGLE_DEVICES,
        icon: 'devices',
        permission: DEVICES_VIEW
      },
      {
        title: 'userMenu.logs',
        url: DEVICE_LOGS,
        icon: 'history',
        permission: DEVICES_VIEW
      }
    ]
  },
  {
    title: 'userMenu.organisations',
    url: ORGANISATION,
    icon: 'groups',
    permission: ORGANISATIONS_VIEW,
    children: [
      {
        title: 'userMenu.organisations',
        url: ORGANISATION,
        icon: 'dvr',
        permission: ORGANISATIONS_VIEW
      },
      {
        title: 'userMenu.organisationsOverview',
        url: ORG_OVERVIEW,
        icon: 'overview',
        permission: ORGANISATIONS_OVERVIEW
      },
      {
        title: 'userMenu.preToken',
        url: PRE_PROVISIONING_TOKEN,
        icon: 'tag',
        permission: ZTE_TOKEN_VIEW
      }
    ]
  }
]
