import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import { TableBody, TableCell, TableRow } from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import DateTimeLocale from '~/components/DateTimeLocale'
import FullPageLoading from '~/components/FullPageLoading'

const localization = 'pages.overview.activities.'
const LogInformation = ({ logInfors, isLoading }) => {
  const { t } = useTranslation()
  if (isLoading) {
    return <FullPageLoading />
  }

  return (
    <TableContainer>
      <Table>
        <TableBody>
          {logInfors.map((data, index) => {
            return (
              <TableRow key={index}>
                <TableCell
                  sx={{
                    width: '250px',
                    borderBottom: 'none',
                    fontWeight: 'bold'
                  }}
                >
                  {data.key}:
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: 'none'
                  }}
                >
                  {data.key === t(`${localization}createdAt`) ? (
                    <DateTimeLocale datetime={data.value} />
                  ) : (
                    <> {data.value}</>
                  )}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

LogInformation.propTypes = {
  isLoading: PropTypes.bool,
  logInfors: PropTypes.array
}

export default LogInformation
