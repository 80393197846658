export const activityLogHeadCells = [
  { id: 'id', numeric: false, disablePadding: false, label: 'id', orderable: true },
  { id: 'user', numeric: false, disablePadding: false, label: 'user', orderable: false },
  { id: 'subject_type', numeric: false, disablePadding: false, label: 'account', orderable: false },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'action',
    orderable: false
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'description',
    orderable: false
  },
  { id: 'created_at', numeric: false, disablePadding: false, label: 'createdAt', orderable: true }
]
