import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  Typography
} from '@mui/material'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CancelButton } from '~/components/Button/CancelButton'
import { LoadingButton } from '~/components/Button/LoadingButton'
import CustomModal from '~/components/Modal/CustomModal'
import { useUserPermission } from '~/hooks/useUserPermission'
import {
  useSwitchRepairFLowMutation,
  useUpdateIntuneEnrollTriggerMutation
} from '~/pages/HealthCheck/mutation'
import { useIntuneEnrollTriggerListQuery } from '~/pages/HealthCheck/query'
import { isOverviewPage } from '~/utils/helpers'
import EditGroupTag from '../StudentGroupTag/EditGroupTag'
import OverviewEditGroupTag from '../StudentGroupTag/OverviewEditGroupTag'
import { useRemoveOrganisationFromTenantMutation } from '../mutation'
import { useOrganisationDetailQuery, useOrganisationListOverviewQuery } from '../query'

const OrganisationDetails = ({ organisationId }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const { userAbility } = useUserPermission()
  const [openRemoveOrganisationModel, setOpenRemoveOrganisationModel] = useState(false)
  const formRef = useRef(null)

  const { mutate: removeOrganisationFromTenant, isLoading: isRemoving } =
    useRemoveOrganisationFromTenantMutation(organisationId)
  const { mutate: updateIet } = useUpdateIntuneEnrollTriggerMutation(organisationId)
  const { mutate: updateRepairFlow } = useSwitchRepairFLowMutation(organisationId)

  const { data: ietList } = useIntuneEnrollTriggerListQuery(isOverviewPage())
  const { data: organisations } = useOrganisationListOverviewQuery(!isOverviewPage())

  const {
    data: organisationDetail,
    isLoading: isLoadingDetails,
    isRefetching: isRefetchingDetail,
    refetch: refetchOrgDetail,
    isSuccess
  } = useOrganisationDetailQuery(organisationId)

  const isDetailLoading = isLoadingDetails || isRefetchingDetail

  const localization = 'pages.organisation.'

  const { setValue, getValues, control, handleSubmit } = useForm({})

  // MODAL REMOVE ORGANISATION FROM TENANT
  const removeOrganisationPopup = () => {
    setOpenRemoveOrganisationModel(true)
  }

  const handleCloseRemoveOrganisationModal = () => {
    setOpenRemoveOrganisationModel(false)
  }

  // HANDLE SUBMIT REMOVE ORGANISATION FROM TENANT
  const handleSubmitRemoveOrganisationModal = useCallback(() => {
    removeOrganisationFromTenant(organisationId)
    setOpenRemoveOrganisationModel(false)
    refetchOrgDetail()
  }, [organisationId, refetchOrgDetail, removeOrganisationFromTenant])

  const handleChange = useCallback(
    e => {
      setValue('intune_enroll_trigger', e.target.value)
      if (formRef.current) {
        formRef.current.requestSubmit()
      }
    },
    [setValue]
  )

  const handleSwitchRepairFlow = useCallback(
    e => {
      setValue('enable_repair_flow', e.target.value)
      updateRepairFlow(getValues())
    },
    [getValues, setValue, updateRepairFlow]
  )

  const onSubmit = useCallback(() => {
    updateIet(getValues())
  }, [getValues, updateIet])

  const renderIetForm = useMemo(() => {
    if (!isOverviewPage() || !isSuccess) {
      return (
        <p>
          {t(`${localization}enrollTrigger`)}: {organisationDetail?.intune_enroll_trigger}
        </p>
      )
    }

    return (
      <Grid container alignItems='center'>
        <Grid item xs={12} md={2}>
          {t(`${localization}enrollTrigger`)}:
        </Grid>
        <Grid item xs={12} md={10}>
          <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <FormControl fullWidth>
              <Controller
                name='intune_enroll_trigger'
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    renderValue={selected => {
                      return selected ?? 'do_nothing (Do nothing)'
                    }}
                    onChange={handleChange}
                    value={organisationDetail?.intune_enroll_trigger}
                    displayEmpty
                  >
                    <MenuItem value={null}>do_nothing (Do nothing)</MenuItem>
                    {ietList?.map(value => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </form>
        </Grid>
      </Grid>
    )
  }, [
    control,
    handleChange,
    handleSubmit,
    ietList,
    isSuccess,
    onSubmit,
    organisationDetail?.intune_enroll_trigger,
    t
  ])

  const renderRepairFlowSwitch = useMemo(() => {
    return (
      <Grid container alignItems='center'>
        <Grid item xs={2}>
          <Typography sx={{ marginTop: '6px' }}>{t(localization + `repairFlow`)}:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Switch
            label={1}
            checked={organisationDetail?.enable_repair_flow}
            onChange={event => handleSwitchRepairFlow(event)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Grid>
      </Grid>
    )
  }, [handleSwitchRepairFlow, organisationDetail?.enable_repair_flow, t])

  const flattenOrganisations = useCallback(arr => {
    return arr.flatMap(item => [item, ...flattenOrganisations(item.childrens || [])])
  }, [])

  const flatOrganisations = useMemo(() => {
    return flattenOrganisations(organisations || [])
  }, [flattenOrganisations, organisations])

  if (isDetailLoading && !isOverviewPage()) {
    return (
      <Grid container justifyContent='center' alignItems='center' minHeight={200}>
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }

  const foundOrganisation = flatOrganisations.find(
    i => i.name === organisationDetail?.name && !organisationDetail?.is_temporary_removed
  )

  if (!organisationDetail || (!foundOrganisation && !isOverviewPage())) {
    return null
  }

  return (
    <Paper sx={{ padding: 4 }}>
      <Grid container>
        <Grid container justifyContent='space-between' flexDirection='row'>
          <Grid>
            <Typography variant='h6'>{t(`${localization}organisationInformation`)}</Typography>
          </Grid>
          <Grid justifyContent='space-between' flexDirection='row' display='flex'>
            <Box sx={{ m: 0.5 }}>
              <LoadingButton
                onClick={e =>
                  removeOrganisationPopup(e, organisationDetail?.id, organisationDetail?.name)
                }
                loading={isRemoving}
                disabled={!isOverviewPage() && !userAbility.organisations.canRemoveTenant}
              >
                {t(`${localization}removeOrganisationFromTenant`)}
              </LoadingButton>
            </Box>
            <Box sx={{ m: 0.5 }}>
              <LoadingButton
                onClick={e => setOpen(true)}
                disabled={!!isOverviewPage() && userAbility.organisations.groupTag.canUpdate}
              >
                {t(`${localization}updateGroupTag`)}
              </LoadingButton>
              {isOverviewPage() && (
                <OverviewEditGroupTag
                  open={open}
                  setOpen={setOpen}
                  organisationId={organisationId}
                  organisationDetail={organisationDetail}
                />
              )}
              {!isOverviewPage() && (
                <EditGroupTag
                  open={open}
                  setOpen={setOpen}
                  organisationId={organisationId}
                  organisationDetail={organisationDetail}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <p>
        {t(`${localization}Id`)}: {organisationDetail?.id}
      </p>
      <p>
        {t(`${localization}externalId`)}: {organisationDetail?.external_id}
      </p>
      <p>
        {t(`${localization}entityId`)}: {organisationDetail?.entity_id}
      </p>
      <p>
        {t(`${localization}name`)}: {organisationDetail?.name}
      </p>
      <p>
        {t(`${localization}nameShort`)}: {organisationDetail?.name_short}
      </p>
      <p>
        {t(`${localization}parentExternalId`)}: {organisationDetail?.parent_external_id}
      </p>
      {renderIetForm}
      {renderRepairFlowSwitch}
      <p>
        {t(`${localization}defaultGroupTag`)}: {organisationDetail?.default_group_tag}
      </p>
      <p>
        {t(`${localization}defaultStaffGroupTag`)}: {organisationDetail?.default_group_tag_staff}
      </p>
      <p>
        {t(`${localization}groupTagLoaner`)}: {organisationDetail?.default_group_tag_loaner}
      </p>
      <p>
        {t(`${localization}contact`)}: {organisationDetail?.contact_id}
      </p>

      <CustomModal open={openRemoveOrganisationModel} onClose={handleCloseRemoveOrganisationModal}>
        <Typography variant='h6'>
          {t(`${localization}doYouWantToRemoveOrganisationFromTenant`)}: {organisationDetail?.name}?
        </Typography>
        <Stack sx={{ mt: 2 }} direction='row-reverse' gap={1}>
          <LoadingButton onClick={handleSubmitRemoveOrganisationModal} loading={isRemoving}>
            {t(`${localization}confirm`)}
          </LoadingButton>
          <CancelButton onClick={handleCloseRemoveOrganisationModal} />
        </Stack>
      </CustomModal>
    </Paper>
  )
}

OrganisationDetails.propTypes = {
  organisationId: PropTypes.number
}

export default OrganisationDetails
