/* eslint-disable no-underscore-dangle */
import Axios from 'axios'
import { KEY_AUTH_TOKEN } from '~/constants/constants'

const REQUEST_TIMEOUT = 30000
const REQUEST_FORM_TIMEOUT = 30000

export const axiosClient = Axios.create({
  baseURL: import.meta.env.VITE_BASE_API_URL,
  headers: {
    Accept: 'application/json',
    ContentType: 'application/json'
  },
  timeout: REQUEST_TIMEOUT
})

export const axiosFormClient = Axios.create({
  baseURL: import.meta.env.VITE_BASE_API_URL,
  headers: {
    Accept: 'application/json',
    contentType: 'multipart/form-data'
  },
  processData: false,
  mimeType: 'multipart/form-data',
  contentType: false,
  timeout: REQUEST_FORM_TIMEOUT
})

axiosClient.interceptors.request.use(config => {
  // If the request is a GET request and the 'locale' param is missing, add it
  if (config.method === 'get' && !config.params?.locale) {
    config.params = {
      ...config.params,
      locale: localStorage.getItem('LOCALE') || 'nl'
    }
  }

  return config
})

axiosClient.interceptors.response.use(
  response => {
    return response
  }
  // error => {
  //   // if (error.response.status === 401) {
  //   //   if (window.location.pathname !== SIGNIN) {
  //   //     localStorage.removeItem(STORAGE_TOKEN_KEY);
  //   //     window.location.replace(SIGNIN);
  //   //   }
  //   // }
  //   if (error.response) {
  //     const message = parseApiErrorMessage(error)
  //     enqueueSnackbar({
  //       message,
  //       variant: 'error'
  //     })
  //   }

  //   return Promise.reject(error)
  // }
)

export const setApiClientAuthToken = t => {
  axiosClient.defaults.headers.common.Authorization = `Bearer ${t}`
  axiosClient.defaults.headers.common['X-CSRF-TOKEN'] = t
  axiosFormClient.defaults.headers.common.Authorization = `Bearer ${t}`
  axiosFormClient.defaults.headers.common['X-CSRF-TOKEN'] = t
}

setApiClientAuthToken(localStorage.getItem(KEY_AUTH_TOKEN)) // initiate the token from localStorage

export default axiosClient
