import { Box, Divider, Typography } from '@mui/material'
import propTypes from 'prop-types'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import DateTimeLocale from '~/components/DateTimeLocale'
import { useSnackbar } from '~/hooks/useSnackbar'
import { selectSnackbar } from '~/store/snackbar/selector'
import { unsetSnackbar } from '~/store/snackbar/slice'
import { isValidDateTimeString } from '~/utils/helpers'
import DataNotFound from '../DataNotFound'

const RegisterSessionLogDetail = ({ data }) => {
  const { t } = useTranslation()

  const snackbarData = useSelector(selectSnackbar)
  const { showSnackbar, snackbar } = useSnackbar()
  const dispatch = useDispatch()
  useEffect(() => {
    if (snackbarData) {
      showSnackbar(snackbarData.message, snackbarData.type)
    }
    return () => {
      dispatch(unsetSnackbar({}))
    }
  })

  const localization = 'pages.device.detail'

  if (Object.keys(data).length === 0) {
    return <DataNotFound />
  }

  return (
    <>
      {snackbar}
      <Divider style={{ marginBottom: 20 }} />
      <Typography variant='h6'>{t(`${localization}.deviceSessionInformation`)}</Typography>
      {Object.keys(data).map(key => {
        const value = data[key]
        if (key === 'hash') {
          return (
            <Box sx={{ wordBreak: 'break-word' }} key={key}>
              hash: {`${value}`}
            </Box>
          )
        }

        if (isValidDateTimeString(value)) {
          return (
            <div key={key}>
              {key} : <DateTimeLocale datetime={value} />
            </div>
          )
        }

        return (
          <div key={key}>
            {key} : {`${value}`}
          </div>
        )
      })}
    </>
  )
}

RegisterSessionLogDetail.propTypes = {
  data: propTypes.object
}

export default RegisterSessionLogDetail
