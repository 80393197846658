import RegisterSessionLog from '~/components/RegisterDeviceSession/RegisterSessionLog'
import { useEntitiesQuery } from '~/pages/HealthCheck/query'
import { useOrganisationListQuery, useRegisterSessionQuery, useTenantListQuery } from './query'

const OverviewRegisterSession = () => {
  const { data: orgData } = useOrganisationListQuery()
  const { data: entitiesData } = useEntitiesQuery()
  const { data: tenants } = useTenantListQuery()

  return (
    <RegisterSessionLog
      query={useRegisterSessionQuery}
      entitiesData={entitiesData}
      orgData={orgData}
      tenants={tenants}
    />
  )
}

export default OverviewRegisterSession
