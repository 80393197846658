import { Box, Breadcrumbs, Card, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectAdmin } from '~/store/adminAuth/selector'
import { selectUser } from '~/store/auth/selector'
import { isOverviewPage } from '~/utils/helpers'
import DataNotFound from '~/components/DataNotFound'
import Setting from './Setting'

const UserSetting = () => {
  const { t } = useTranslation()
  const userSelector = isOverviewPage() ? selectAdmin : selectUser
  const user = useSelector(userSelector)

  if (!user) {
    return <DataNotFound />
  }

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography>User Setting</Typography>
      </Breadcrumbs>

      <Card>
        <Box p={2}>
          <Typography variant='h5' mb={3}>
            {t('profileSetting.settingManagement')}
          </Typography>
        </Box>
        <Box p={2}>
          <Setting user={user} />
        </Box>
      </Card>
    </>
  )
}

export default UserSetting
