import SearchIcon from '@mui/icons-material/Search'
import { FormControl, InputAdornment, OutlinedInput } from '@mui/material'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const SearchToolBar = ({ filterSearch, onFilterSearch, onChange, placeholder, ...props }) => {
  const { t } = useTranslation()

  return (
    <FormControl
      sx={{
        m: 1,
        width: '100%',
        bgcolor: '#ffffff'
      }}
      {...props}
    >
      <OutlinedInput
        id='outlined-adornment-amount'
        inputProps={{ 'aria-label': 'search' }}
        defaultValue={filterSearch}
        onKeyPress={onFilterSearch}
        onChange={onChange}
        startAdornment={
          <InputAdornment position='start'>
            <SearchIcon />
          </InputAdornment>
        }
        placeholder={placeholder ? t(placeholder) : null}
      />
    </FormControl>
  )
}

SearchToolBar.defaultProps = {
  filterSearch: '',
  placeholder: ''
}

SearchToolBar.propTypes = {
  filterSearch: PropTypes.string,
  onFilterSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string
}

export default SearchToolBar
