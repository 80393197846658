import RegisterSessionLog from '~/components/RegisterDeviceSession/RegisterSessionLog'
import { useRegisterSessionQuery } from './query'
import { useOrganisationListOverviewQuery } from '../Organisations/Organisations/query'
import { useMemo } from 'react'

const DeviceRegisterSession = () => {
  const { data: orgData } = useOrganisationListOverviewQuery(true, {
    filter: true
  })

  const entitiesData = [
    {
      id: 1,
      entity_tid: 'trc_nl',
      name: 'The Rent Company NL'
    }
  ]

  const orgs = useMemo(() => {
    return (
      orgData?.map(item => {
        return {
          id: item.id,
          label: item.name,
          external_id: item.external_id
        }
      }) || []
    )
  }, [orgData])

  return (
    <RegisterSessionLog
      query={useRegisterSessionQuery}
      entitiesData={entitiesData}
      orgData={orgs}
    />
  )
}

export default DeviceRegisterSession
