import { useQuery } from 'react-query'
import deviceApi from '~/api/deviceApi'
import { removeEmptyValue } from '~/utils/helpers'

export const useRegisterSessionQuery = params => {
  return useQuery(['register_sessions', params], async () => {
    const p = removeEmptyValue(params)
    const { data } = await deviceApi.registerLogs(p)
    return { data: data.data, total: data.meta.total }
  })
}

export const useRegisterSessionDetailQuery = id => {
  return useQuery(['register_session_detail', id], async () => {
    const { data } = await deviceApi.registerLogDetail(id)
    return data
  })
}
