import { Box, Button, Grid, Toolbar } from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import SearchToolBar from '~/components/SearchToolBar'
import { selectFilterStateObject } from '~/store/filter/selector'
import { setFilterObject } from '~/store/filter/slice'
import RangeDateFieldPicker from '../RangeDatePicker/RangeDateFieldPicker'
import DropDownTable from './DropDownTable'

const TableFilters = ({
  selectGroup = [],
  search,
  searchHandler,
  searchPlaceHolder,
  onEnterSearch,
  page = '',
  showDatePicker = true,
  rangeDateGroup = [],
  rangeDate = [null, null],
  setRangeDate = () => {}
}) => {
  const filterRedux = useSelector(selectFilterStateObject)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const p = 1

  useEffect(() => {
    if (page !== filterRedux.page) {
      return
    }

    selectGroup.forEach(element => {
      if (element.value === '') {
        const found = filterRedux.filter.find(filter => filter.name === element.label)
        if (found) {
          element.setValue(found.value)
        }
      }
    })

    rangeDateGroup.forEach(element => {
      if (element.value === null) {
        const found = filterRedux.filter.find(filter => filter.name === element.label)
        if (found) {
          element.setValue(found.value)
        }
      }
    })
  }, [rangeDateGroup, filterRedux.filter, filterRedux.page, page, selectGroup])

  const handleClearFilter = () => {
    selectGroup.forEach(element => {
      dispatch(
        setFilterObject({
          p,
          filter: {
            name: element.label,
            value: element?.multiple === true ? ['All'] : 'All'
          }
        })
      )
      element.setValue(element?.multiple === true ? [] : '')
    })
    rangeDateGroup.forEach(el => {
      dispatch(
        setFilterObject({
          p,
          filter: {
            name: el.label,
            value: [null, null]
          }
        })
      )
      el.setValue([null, null])
    })
  }

  return (
    <Toolbar>
      <Grid container spacing={0.5}>
        {selectGroup.map((item, index) => (
          <DropDownTable
            filterObject={item}
            key={index}
            page={page}
            disabled={item?.disabled ?? false}
          />
        ))}
        {showDatePicker && rangeDateGroup.length > 0 && (
          <>
            {rangeDateGroup.map((date, index) => (
              <Grid ml={1} item key={index}>
                <RangeDateFieldPicker filterObject={date} page={page} />
              </Grid>
            ))}
          </>
        )}
        {selectGroup?.length > 0 && (
          <Button
            variant='contained'
            size='small'
            sx={{
              textTransform: 'none',
              backgroundColor: '#76B72A',
              height: '55px',
              ml: '15px',
              mt: '12px'
            }}
            type={'button'}
            onClick={() => {
              handleClearFilter()
            }}
          >
            {t('general.clearFilter')}
          </Button>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Grid item xs={12} sm={2}>
          <SearchToolBar
            filterSearch={search}
            onFilterSearch={searchHandler}
            placeholder={searchPlaceHolder}
            onEnterSearch={onEnterSearch}
          />
        </Grid>
      </Grid>
    </Toolbar>
  )
}

TableFilters.propTypes = {
  selectGroup: PropTypes.array,
  search: PropTypes.string,
  searchHandler: PropTypes.func,
  searchPlaceHolder: PropTypes.string,
  onEnterSearch: PropTypes.func,
  page: PropTypes.string,
  showDatePicker: PropTypes.bool,
  rangeDateGroup: PropTypes.array,
  rangeDate: PropTypes.array,
  setRangeDate: PropTypes.func
}

TableFilters.defaultProps = {
  showDatePicker: false
}

export default TableFilters
