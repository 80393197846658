import { Breadcrumbs, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { NavLink, useParams } from 'react-router-dom'
import FullPageLoading from '~/components/FullPageLoading'
import RegisterSessionLogDetail from '~/components/RegisterDeviceSession/RegisterSessionLogDetail'
import { ADMIN_DEVICE_REGISTER_SESSIONS } from '~/constants/Routes'
import { useRegisterSessionDetailQuery } from './query'

const OverviewDeviceRegisterSessionDetail = () => {
  const { t } = useTranslation()
  const { sessionId } = useParams()
  const { data, isLoading } = useRegisterSessionDetailQuery(sessionId)

  if (isLoading) {
    return <FullPageLoading />
  }

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography>
          <NavLink to={ADMIN_DEVICE_REGISTER_SESSIONS}>
            {t('general.registerSessionOverview')}
          </NavLink>
        </Typography>
        <Typography>{data?.data?.serial}</Typography>
      </Breadcrumbs>
      <RegisterSessionLogDetail data={data?.data ?? {}} />
    </>
  )
}
export default OverviewDeviceRegisterSessionDetail
