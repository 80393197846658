import {
  Chip,
  Divider,
  Link as MuiLink,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useRef, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { Link } from 'react-router-dom'
import BreadCrumb from '~/components/BreadCrumb'
import DataNotFound from '~/components/DataNotFound'
import DateTimeLocale from '~/components/DateTimeLocale'
import PaginationTable from '~/components/PaginationTable'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import { ADMIN_ACTIVITY_LOG_DETAIL, ADMIN_ACTIVITY_LOGS } from '~/constants/Routes'
import useTable from '~/hooks/useTable'
import { getActionColor } from '~/utils/helpers'
import { activityLogHeadCells } from './activityLogHeadCells'
import ActivityLogTableFilter from './ActivityLogTableFilter'
import { useActivityLogQuery } from './query'
import debounce from 'lodash.debounce'

const breadCrumbConfig = [
  { title: 'Overview', route: ADMIN_ACTIVITY_LOGS },
  {
    title: 'Activity Logs',
    route: ADMIN_ACTIVITY_LOGS
  }
]

const ActivityLogTableBody = ({ isLoading, logs }) => {
  if (isLoading) {
    return <TableLoading colSpan={activityLogHeadCells.length} />
  }

  return (
    <TableBody>
      {logs?.length === 0 && <DataNotFound colSpan={activityLogHeadCells.length} />}
      {logs?.length > 0 &&
        logs?.map(row => {
          const chipcolor = getActionColor(row.properties?.action)
          const isAdmin = row.causer_type === 'App\\Models\\Admin'
          const isUser = row.causer_type === 'App\\Models\\User'
          const isService = row.causer_type === 'App\\Models\\ServiceAccount'
          const link = isAdmin
            ? `/overview/super-users/${row.user_id}`
            : isUser
              ? `/overview/users/${row.user_id}`
              : null

          const detailLink = ADMIN_ACTIVITY_LOG_DETAIL.replace(':id', row.id)

          return (
            <TableRow key={row.id}>
              <TableCell>
                <Link to={detailLink}>
                  <MuiLink>{row.id}</MuiLink>
                </Link>
              </TableCell>
              <TableCell>
                {link ? (
                  <Link to={link}>
                    <MuiLink>{row.user_id}</MuiLink>
                  </Link>
                ) : (
                  <>{row.user_id}</>
                )}
              </TableCell>
              <TableCell>
                {isAdmin
                  ? 'Overview User'
                  : isUser
                    ? 'School User'
                    : isService
                      ? 'Service Account'
                      : ''}
              </TableCell>
              <TableCell>
                <Chip
                  label={row?.properties?.action.toString().toUpperCase()}
                  color={chipcolor}
                  size='small'
                />
              </TableCell>
              <TableCell>
                {row.subject_type?.replace('App\\Models\\', '')} {row.subject_id}
              </TableCell>
              <TableCell>
                <DateTimeLocale datetime={row?.created_at} />
              </TableCell>
            </TableRow>
          )
        })}
    </TableBody>
  )
}

ActivityLogTableBody.propTypes = {
  logs: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired
}
const localization = 'pages.overview.activities.'
const ActivityLogs = () => {
  const [action, setAction] = useState('')
  const [userType, setUserType] = useState('')
  const [user, setUser] = useState('')
  const [rangeDate, setRangeDate] = useState([null, null])

  const queryAttributes = useMemo(() => {
    return {
      action: action ?? '',
      userType: userType ?? '',
      userValue: user ?? '',
      from: rangeDate[0] ?? '',
      to: rangeDate[1] ?? rangeDate[0]
    }
  }, [action, rangeDate, user, userType])

  const [debouncedQueryAttributes, setDebouncedQueryAttributes] = useState(queryAttributes)

  useEffect(() => {
    const debouncedSetAttributes = debounce(() => {
      setDebouncedQueryAttributes(queryAttributes)
    }, 1000)

    debouncedSetAttributes()
    return () => {
      debouncedSetAttributes.cancel()
    }
  }, [queryAttributes])

  const {
    results,
    data,
    search,
    isLoading,
    order,
    page,
    orderBy,
    searchHandler,
    sortHandler,
    changePageHandler,
    setPage,
    onEnterSearch
  } = useTable({
    query: useActivityLogQuery,
    initialStates: {
      order: 'desc',
      page: 1,
      orderBy: 'created_at'
    },
    queryAttributes: debouncedQueryAttributes
  })

  const refValue = useRef({
    action: '',
    user: '',
    userType: '',
    rangeDate: [null, null]
  })

  useEffect(() => {
    if (
      action !== refValue.current.action ||
      userType !== refValue.current.userType ||
      user !== refValue.current.user ||
      (rangeDate[0] !== refValue.current.rangeDate[0] &&
        rangeDate[1] !== refValue.current.rangeDate[1])
    ) {
      setPage(1)
      refValue.current.action = action
      refValue.current.user = user
      refValue.current.userType = userType
      refValue.current.rangeDate[0] = rangeDate[0]
      refValue.current.rangeDate[1] = rangeDate[1]
    }
  }, [action, rangeDate, setPage, user, userType])

  return (
    <>
      <BreadCrumb breadcrumbs={breadCrumbConfig} />
      <Divider />
      <Paper elevation={1}>
        <ActivityLogTableFilter
          {...{
            search,
            searchHandler,
            onEnterSearch,
            user,
            setUser,
            action,
            setAction,
            rangeDate,
            setRangeDate,
            userType,
            setUserType
          }}
        />
        <Divider />

        <TableContainer>
          <Table>
            <TableHeader
              headCells={activityLogHeadCells}
              onRequestSort={sortHandler}
              order={order}
              orderBy={orderBy}
              localization={localization}
            />
            <ActivityLogTableBody isLoading={isLoading} logs={results} />
          </Table>
        </TableContainer>
        <PaginationTable
          data={data}
          page={page}
          size='medium'
          handleChangePage={changePageHandler}
        />
      </Paper>
    </>
  )
}

export default ActivityLogs
