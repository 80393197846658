import { enqueueSnackbar } from 'notistack'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  bulkDeleteDeviceApi,
  bulkDeleteGoogleDeviceApi,
  deleteDeviceApi,
  deleteGoogleDeviceApi,
  listDevicesApi,
  listGoogleDevicesApi,
  listGoogleHardwaresApi,
  repairDeviceApi
} from '~/api'
import { parseApiErrorMessage, removeEmptyValue } from '~/utils/helpers'

export const useAzureDeviceListOverviewQuery = (params, refetchInterval = 0) => {
  return useQuery(
    ['azure_devices_overview', params],
    async () => {
      const p = removeEmptyValue(params)
      const { data } = await listDevicesApi(p)
      return { data: data?.data, total: data?.meta?.total }
    },
    {
      refetchInterval,
      onError: e => {
        const message = parseApiErrorMessage(e)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      },
      retry: false
    }
  )
}

export const useDeregisterDeviceMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async id => {
      await deleteDeviceApi(id)
    },
    {
      onError: error => {
        enqueueSnackbar({
          message: parseApiErrorMessage(error),
          variant: 'error'
        })
      },
      onSuccess: () => {
        queryClient.invalidateQueries('azure_devices_overview')
      }
    }
  )
}

export const useBulkDeleteDeviceMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async ids => {
      await bulkDeleteDeviceApi(ids)
    },
    {
      onError: error => {
        enqueueSnackbar({
          message: parseApiErrorMessage(error),
          variant: 'error'
        })
      },
      onSuccess: () => {
        queryClient.invalidateQueries('azure_devices_overview')
      }
    }
  )
}

export const useGoogleDeviceListOverviewQuery = params => {
  return useQuery(
    ['google_devices_overview', params],
    async () => {
      const p = removeEmptyValue(params)
      const { data } = await listGoogleDevicesApi(p)
      return { data: data.data, total: data.meta.total }
    },
    {
      onError: e => {
        const message = parseApiErrorMessage(e)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      },
      retry: false
    }
  )
}

export const useGoogleHardwareModelListQuery = params => {
  return useQuery(
    ['google_hardwares', params],
    async () => {
      const { data } = await listGoogleHardwaresApi()
      return data.data
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: e => {
        const message = parseApiErrorMessage(e)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      }
    }
  )
}

export const useDeregisterGoogleDeviceMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async id => {
      await deleteGoogleDeviceApi(id)
    },
    {
      onError: error => {
        enqueueSnackbar({
          message: parseApiErrorMessage(error),
          variant: 'error'
        })
      },
      onSuccess: () => {
        queryClient.invalidateQueries('google_devices_overview')
      }
    }
  )
}

export const useRepairDeviceMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async params => {
      const data = await repairDeviceApi(params)
      return data
    },
    {
      onSuccess: data => {
        enqueueSnackbar({
          message: data?.data?.message ?? 'Processing device',
          variant: 'success'
        })
        queryClient.invalidateQueries('azure_devices_overview')
      },
      onError: error => {
        enqueueSnackbar({
          message: parseApiErrorMessage(error),
          variant: 'error'
        })
      }
    }
  )
}

export const useBulkDeleteGoogleDeviceMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async ids => {
      await bulkDeleteGoogleDeviceApi(ids)
    },
    {
      onError: error => {
        enqueueSnackbar({
          message: parseApiErrorMessage(error),
          variant: 'error'
        })
      },
      onSuccess: () => {
        queryClient.invalidateQueries('google_devices_overview')
      }
    }
  )
}
