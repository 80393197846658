import { enqueueSnackbar } from 'notistack'
import { useMutation, useQueryClient } from 'react-query'
import overviewUserApi from '~/api/overviewUserApi'
import userApi from '~/api/userApi'
import { isOverviewPage, parseApiErrorMessage } from '~/utils/helpers'

export const useUpdateSettingUserMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ['update_setting'],
    async payload => {
      const api = isOverviewPage() ? overviewUserApi : userApi
      const { data } = await api.updateSettingUser(payload)
      return data
    },
    {
      onSuccess: () => {
        enqueueSnackbar({
          message: 'Updated timezone successful',
          variant: 'success'
        })

        if (isOverviewPage()) {
          queryClient.invalidateQueries('admin_profile')
        } else {
          queryClient.invalidateQueries('profile')
        }
      },
      onError: e => {
        const message = parseApiErrorMessage(e)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      },
      retry: false
    }
  )
}
