import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Divider,
  Grid,
  TextField,
  Toolbar,
  Typography
} from '@mui/material'
import propTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PaginationTable from '~/components/PaginationTable'
import SearchToolBar from '~/components/SearchToolBar'
import useTable from '~/hooks/useTable'
import RegisterSessionTable from './RegisterSessionTable'

const RegisterSessionLog = ({ query, orgData, entitiesData, tenants }) => {
  const { t } = useTranslation()

  const [orgFilter, setOrgFilter] = useState('')
  const [entityFilter, setEntityFilter] = useState('')
  const [tenantFilter, setTenantFilter] = useState('')

  const {
    data,
    search,
    isLoading,
    order,
    page,
    orderBy,
    searchHandler,
    sortHandler,
    changePageHandler,
    setPage
  } = useTable({
    query,
    initialStates: {
      order: 'desc',
      page: 1,
      orderBy: 'created_at'
    },
    queryAttributes: {
      external_id: orgFilter?.external_id,
      entity_id: entityFilter?.id,
      tenant_id: tenantFilter?.id
    }
  })

  const entities = useMemo(() => {
    if (entitiesData) {
      return entitiesData.map(option => ({
        label: option.name + ' (' + option.entity_tid + ')',
        id: option.id
      }))
    }
    return []
  }, [entitiesData])

  const orgs = useMemo(() => {
    if (entityFilter?.id && orgData) {
      return orgData.filter(option => option.entity_id === entityFilter.id)
    }
    return orgData || []
  }, [entityFilter?.id, orgData])

  useEffect(() => {
    if (entityFilter?.id) {
      if (orgFilter?.entity_id !== entityFilter.id) {
        setOrgFilter('') // reset filter
      }
    }
  }, [entityFilter?.id, orgFilter?.entity_id])

  useEffect(() => {
    if (orgFilter || entityFilter || tenantFilter) {
      setPage(1) // reset page
    }
  }, [orgFilter, entityFilter, tenantFilter, setPage])

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography>{t('general.registerSessionOverview')}</Typography>
      </Breadcrumbs>
      <Divider />
      <Toolbar>
        <Grid container spacing={2} padding={1.5}>
          <Grid item xs={6} sm={3}>
            <Autocomplete
              id='typeEntity'
              options={entities || []}
              value={entityFilter}
              isOptionEqualToValue={(option, value) => option.id === value}
              renderInput={params => <TextField {...params} label={'Entity'} />}
              onChange={(event, newValue) => {
                setEntityFilter(newValue)
              }}
            />
          </Grid>
          <Grid item xs={6} sm={2}>
            <Autocomplete
              id='type'
              options={orgs || []}
              value={orgFilter}
              isOptionEqualToValue={(option, value) => option.id === value}
              renderInput={params => (
                <TextField {...params} label={t('pages.organisation.organisation')} />
              )}
              onChange={(event, newValue) => {
                setOrgFilter(newValue)
              }}
            />
          </Grid>
          {window.location.href.indexOf('/overview') > -1 && (
            <Grid item xs={6} sm={2}>
              <Autocomplete
                id='type'
                options={tenants || []}
                value={tenantFilter}
                isOptionEqualToValue={(option, value) => option.id === value}
                renderInput={params => <TextField {...params} label={'Tenant'} />}
                onChange={(event, newValue) => {
                  setTenantFilter(newValue)
                }}
              />
            </Grid>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Grid item xs={6} sm={3}>
            <SearchToolBar
              filterSearch={search}
              onChange={searchHandler}
              placeholder='serials_or_State'
            />
          </Grid>
        </Grid>
      </Toolbar>
      <Divider />
      <RegisterSessionTable
        order={order}
        orderBy={orderBy}
        isLoading={isLoading}
        handleRequestSort={sortHandler}
        data={data}
      />
      <PaginationTable data={data} page={page} size='medium' handleChangePage={changePageHandler} />
    </>
  )
}

RegisterSessionLog.propTypes = {
  query: propTypes.func.isRequired,
  orgData: propTypes.array,
  entitiesData: propTypes.array,
  tenants: propTypes.array
}

RegisterSessionLog.defaultProps = {
  orgData: [],
  entitiesData: [],
  tenants: []
}

export default RegisterSessionLog
