import { enqueueSnackbar } from 'notistack'
import { overviewGetAllGuard, overviewGetAllPermissionsByGuard } from '~/api'
import { useCustomQuery as useQuery } from '~/hooks/useQuery'
import { parseApiErrorMessage } from '~/utils/helpers'

export const useGetAllPermissionsQuery = guardName => {
  return useQuery(
    ['roles_create', 'get_all_permissions', guardName],
    async () => {
      const { data } = await overviewGetAllPermissionsByGuard(guardName)
      return data?.data
    },
    {
      onError: error => {
        enqueueSnackbar({
          message: parseApiErrorMessage(error),
          variant: 'error'
        })
      },
      retry: false,
      enabled: !!guardName
    }
  )
}
export const useGetAllGuardsQuery = () => {
  return useQuery(
    ['roles_create', 'get_all_guards'],
    async () => {
      const { data } = await overviewGetAllGuard()
      return data?.data
    },
    {
      onError: error => {
        enqueueSnackbar({
          message: parseApiErrorMessage(error),
          variant: 'error'
        })
      },
      retry: false
    }
  )
}
