import { enqueueSnackbar } from 'notistack'
import { useMutation, useQueryClient } from 'react-query'
import organisationApi from '~/api/organisationApi'
import overviewOrganisationApi from '~/api/overviewOrganisationApi'
import { useCustomQuery as useQuery } from '~/hooks/useQuery'
import { isOverviewPage, parseApiErrorMessage, removeEmptyValue } from '~/utils/helpers'

export const useOrganisationListOverviewQuery = (enabled = true, params = {}) => {
  return useQuery(
    ['organisation_overview', params],
    async () => {
      const p = removeEmptyValue(params)
      const { data } = await organisationApi.listOrganisationsApi(p)
      return data.data || []
    },
    {
      onError: e => {
        const message = parseApiErrorMessage(e)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      },
      retry: false,
      enabled
    }
  )
}

export const useUpdateGroupTagMutation = organisationId => {
  const queryClient = useQueryClient()
  return useMutation(
    async tags => {
      const api = isOverviewPage() ? overviewOrganisationApi : organisationApi
      const { data } = await api.updateGroupTagApi(organisationId, tags)
      return data.data
    },
    {
      onSuccess: data => {
        enqueueSnackbar({
          message: 'Successfully updated group tag',
          variant: 'success'
        })

        queryClient.invalidateQueries(['organisation_detail'])
      },
      retry: false
    }
  )
}

export const useOrganisationDetailQuery = organisationId => {
  return useQuery(
    ['organisation_detail', organisationId],
    async () => {
      const api = isOverviewPage() ? overviewOrganisationApi : organisationApi
      const { data } = await api.showOrganisationsApi(organisationId)
      return data.data
    },
    {
      retry: false,
      enabled: organisationId !== null
    }
  )
}
