import propTypes from 'prop-types'
import { useMemo } from 'react'
import { useActivityLogUsersQuery } from './query'
import { useTranslation } from 'react-i18next'
import TableFilters from '~/components/Table/TableFilters'

const localization = 'pages.overview.activities.'

const ActivityLogTableFilter = ({
  search,
  searchHandler,
  onEnterSearch,
  user,
  setUser,
  action,
  setAction,
  rangeDate,
  setRangeDate,
  userType,
  setUserType,
  showRangePicker = true
}) => {
  const { t } = useTranslation()

  const actions = useMemo(
    () => [
      { name: 'All', id: '' },
      { name: 'Created', id: 'Created' },
      { name: 'Updated', id: 'Updated' },
      { name: 'Deleted', id: 'Deleted' }
    ],
    []
  )

  const userTypes = useMemo(
    () => [
      { name: 'All', id: '' },
      { name: 'Overview User', id: 'ADMIN' },
      { name: 'School User', id: 'USER' },
      { name: 'Service Account', id: 'SERVICE_ACCOUNT' }
    ],
    []
  )

  const { data: filterData, isLoading: isUserLoading } = useActivityLogUsersQuery({
    userType
  })

  const filters = useMemo(() => {
    if (!userType || isUserLoading || filterData === undefined) {
      return []
    }

    let result = []
    switch (userType) {
      case 'USER':
        result = filterData.users
        break
      case 'ADMIN':
        result = filterData.admins
        break
      case 'SERVICE_ACCOUNT':
        result = filterData.service_accounts
        break
      default:
        break
    }

    return (
      result?.map(item => {
        return {
          id: item.id,
          name: item.name ?? item.email
        }
      }) ?? []
    )
  }, [filterData, isUserLoading, userType])

  const selectGroup = useMemo(() => {
    return [
      {
        label: t(`${localization}action`),
        setValue: setAction,
        dataSet: actions,
        value: action,
        disabled: false
      },
      {
        label: t(`${localization}userType`),
        setValue: setUserType,
        dataSet: userTypes,
        value: userType,
        disabled: false
      },
      {
        label: t(`${localization}user`),
        setValue: setUser,
        dataSet: filters,
        value: user,
        disabled: !userType
      }
    ]
  }, [action, actions, filters, setAction, setUser, setUserType, t, user, userType, userTypes])

  const rangeDateGroup = useMemo(() => {
    return showRangePicker
      ? [
          {
            label: 'Range Date',
            setValue: setRangeDate,
            value: rangeDate
          }
        ]
      : []
  }, [rangeDate, setRangeDate, showRangePicker])

  return (
    <TableFilters
      {...{
        selectGroup,
        search,
        searchHandler,
        onEnterSearch,
        rangeDateGroup
      }}
      showDatePicker={true}
      searchPlaceHolder={t('pages.overview.activities.searchByDes')}
      page='activity-logs'
    />
  )
}

ActivityLogTableFilter.propTypes = {
  search: propTypes.string,
  searchHandler: propTypes.func,
  serviceCenter: propTypes.string,
  setServiceCenter: propTypes.func,
  onEnterSearch: propTypes.func,
  user: propTypes.string,
  setUser: propTypes.func,
  action: propTypes.string,
  setAction: propTypes.func,
  rangeDate: propTypes.any,
  setRangeDate: propTypes.func,
  userType: propTypes.string,
  setUserType: propTypes.func,
  showRangePicker: propTypes.bool
}

export default ActivityLogTableFilter
