import {
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select
} from '@mui/material'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { setFilterObject } from '~/store/filter/slice'
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

const DropDownTable = ({ filterObject, page, disabled = false }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const onChange = useCallback(
    e => {
      dispatch(
        setFilterObject({
          page,
          filter: {
            name: filterObject.label,
            value: e.target.value
          }
        })
      )
      filterObject.setValue(e.target.value)
    },
    [filterObject, dispatch, page]
  )
  return (
    <Grid item xs={12} sm={filterObject?.sm || 2}>
      <FormControl sx={{ m: 1, width: '100%' }}>
        <InputLabel>{filterObject.label}</InputLabel>
        <Select
          multiple={filterObject?.multiple === true}
          value={filterObject.value || ''}
          onChange={onChange}
          disabled={disabled}
          renderValue={selected => {
            return filterObject?.multiple ? ( // Render value for multi-select
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 0.5
                }}
              >
                {selected?.map(value => {
                  const selectedItem = filterObject.dataSet.filter(v => v.id === value).pop()
                  return <Chip key={selectedItem.id} label={selectedItem.name} variant='outlined' />
                })}
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 0.5
                }}
              >
                {(selected => {
                  return filterObject.dataSet.filter(v => v.id === selected).pop()?.name
                })(selected)}
              </Box>
            )
          }}
          inputProps={{ 'aria-label': 'Without label' }}
          input={<OutlinedInput label={filterObject.label} />}
          MenuProps={MenuProps}
        >
          {filterObject.dataSet.length > 0 ? (
            filterObject.dataSet.map((item, idx) => (
              <MenuItem key={idx} value={item.id}>
                {item.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem>{t(`general.noOption`)}</MenuItem>
          )}
        </Select>
      </FormControl>
    </Grid>
  )
}

DropDownTable.propTypes = {
  filterObject: PropTypes.object.isRequired,
  page: PropTypes.string,
  disabled: PropTypes.bool
}

export default DropDownTable
