import { enqueueSnackbar } from 'notistack'
import overviewUserApi from '~/api/overviewUserApi'
import { useCustomQuery as useQuery } from '~/hooks/useQuery'
import { parseApiErrorMessage, removeEmptyValue } from '~/utils/helpers'

export const useActivityLogQuery = params => {
  return useQuery(
    ['activity_logs', params],
    async () => {
      const p = removeEmptyValue(params)
      const { data } = await overviewUserApi.getActivityLogs(p)
      return { data: data?.data, total: data?.meta?.total }
    },
    {
      onError: e => {
        const message = parseApiErrorMessage(e)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      }
    }
  )
}

export const useActivityLogUsersQuery = params => {
  return useQuery(
    ['activity_log_users', params],
    async () => {
      const p = removeEmptyValue(params)
      const { data } = await overviewUserApi.userLogFilter(p)
      return data
    },
    {
      onError: e => {
        const message = parseApiErrorMessage(e)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      }
    }
  )
}

export const useActivityLogDetailByIdQuery = ({ id }) => {
  return useQuery(
    ['activity_logs_detail', id],
    async () => {
      const { data } = await overviewUserApi.getActivityLogDetail(id)
      return { data: data?.data }
    },
    {
      retry: false,
      enabled: !!id
    }
  )
}
