import {
  TableContainer,
  TableRow,
  TableBody as MuiTableBody,
  TableCell,
  Paper,
  Table,
  Box
} from '@mui/material'
import DataNotFound from '~/components/DataNotFound'
import PropTypes from 'prop-types'
import TableHeader from '~/components/TableHeader'
import { isValidDateTimeString } from '~/utils/helpers'
import DateTimeLocale from '~/components/DateTimeLocale'

const TableBody = ({ data, colSpan, isContainNewData }) => {
  const formatDateTimeData = value => {
    if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value)
    }

    if (typeof value === 'boolean') {
      return value ? 'true' : 'false'
    }

    if (isValidDateTimeString(value)) {
      return <DateTimeLocale datetime={value} />
    }

    return value ?? 'N/A'
  }

  return (
    <MuiTableBody>
      {data.length > 0 ? (
        data.map(row => {
          return (
            <TableRow key={row?.key}>
              <TableCell>
                <Box sx={{ fontWeight: 'bold' }}>{row?.key}</Box>
              </TableCell>
              {isContainNewData && <TableCell>{formatDateTimeData(row?.original)}</TableCell>}

              <TableCell
                sx={{
                  maxWidth: '500px',
                  wordBreak: 'break-word',
                  whiteSpace: 'pre-wrap'
                }}
              >
                {formatDateTimeData(row?.new)}
              </TableCell>
            </TableRow>
          )
        })
      ) : (
        <DataNotFound colSpan={colSpan} />
      )}
    </MuiTableBody>
  )
}

TableBody.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  colSpan: PropTypes.number,
  isContainNewData: PropTypes.bool
}

const DataChangeTable = ({ data, isContainNewData }) => {
  const LogDataChangeHeadCells = isContainNewData
    ? [
        {
          id: 'key',
          label: 'key',
          numeric: false,
          disablePadding: false,
          orderable: false
        },
        {
          id: 'original',
          label: 'original',
          numeric: false,
          disablePadding: false,
          orderable: false
        },
        {
          id: 'new',
          label: 'new',
          numeric: false,
          disablePadding: false,
          orderable: false
        }
      ]
    : [
        {
          id: 'key',
          label: 'key'
        },
        {
          id: 'data',
          label: 'data'
        }
      ]

  return (
    <>
      <Paper elevation={1}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size='medium'>
            <TableHeader
              headCells={LogDataChangeHeadCells}
              localization={'pages.overview.activities.'}
            />
            <TableBody
              data={data}
              colSpan={LogDataChangeHeadCells.length}
              isContainNewData={isContainNewData}
            />
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
}

DataChangeTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  isContainNewData: PropTypes.bool
}
export default DataChangeTable
