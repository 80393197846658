import {
  ADMIN_ACTIVITY_LOGS,
  ADMIN_DASHBOARD,
  ADMIN_DEVICES,
  ADMIN_DEVICE_REGISTER_SESSIONS,
  ADMIN_INVITE_AZURE_TENANTS,
  ADMIN_INVITE_GOOGLE_DOMAIN,
  ADMIN_OVERVIEW,
  ADMIN_REGISTRATION,
  ADMIN_SETTING,
  ADMIN_SUPER_USERS,
  ADMIN_SUPER_USERS_DISABLE,
  ADMIN_USERS,
  ADMIN_USERS_DISABLE,
  ADMIN_USER_COMPANY,
  ADMIN_USER_ROLE
} from '~/constants/Routes'
import {
  ACTIVITY_LOG,
  ADMINISTRATOR_INVITE_AZURE,
  ADMINISTRATOR_INVITE_GOOGLE,
  ADMINISTRATOR_VIEW,
  COMPANY_VIEW,
  DASHBOARD,
  DEVICE_VIEW,
  REGISTER_SESSION_VIEW,
  ROLE_VIEW,
  SCHOOL_USER_DISABLE,
  SCHOOL_USER_VIEW,
  SETTING,
  TENANT_VIEW,
  USER_ADMIN_DISABLE,
  USER_ADMIN_VIEW
} from '~/constants/adminPermission'

export const OVERVIEW_MENU_ITEMS = [
  {
    title: 'adminMenu.dashboard',
    url: ADMIN_DASHBOARD,
    icon: 'dashboard',
    permission: DASHBOARD
  },
  {
    title: 'adminMenu.tenants',
    url: ADMIN_OVERVIEW,
    icon: 'house',
    permission: TENANT_VIEW
  },
  {
    title: 'adminMenu.devices',
    url: ADMIN_DEVICES,
    icon: 'devices',
    permission: DEVICE_VIEW
  },
  {
    title: 'adminMenu.devices_register_session',
    url: ADMIN_DEVICE_REGISTER_SESSIONS,
    icon: 'history',
    permission: REGISTER_SESSION_VIEW
  },
  {
    title: 'adminMenu.administrator.administrator',
    url: ADMIN_INVITE_AZURE_TENANTS,
    icon: 'settings',
    permission: ADMINISTRATOR_VIEW,
    children: [
      {
        title: 'adminMenu.administrator.inviteAzureTenants',
        url: ADMIN_INVITE_AZURE_TENANTS,
        icon: 'send',
        permission: ADMINISTRATOR_INVITE_AZURE
      },
      {
        title: 'adminMenu.administrator.inviteGooleDomain',
        url: ADMIN_INVITE_GOOGLE_DOMAIN,
        icon: 'send',
        permission: ADMINISTRATOR_INVITE_GOOGLE
      },
      {
        title: 'adminMenu.administrator.users',
        url: ADMIN_USERS,
        icon: 'groups',
        permission: 'overview.administrator.users',
        children: [
          {
            title: 'adminMenu.administrator.schoolUsers.registrations',
            url: ADMIN_REGISTRATION,
            icon: 'groups',
            permission: SCHOOL_USER_VIEW
          },
          {
            title: 'adminMenu.administrator.schoolUsers.schoolUsers',
            url: ADMIN_USERS,
            icon: 'groups',
            permission: SCHOOL_USER_VIEW
          },
          {
            title: 'adminMenu.administrator.schoolUsers.disable',
            url: ADMIN_USERS_DISABLE,
            icon: 'person_off',
            permission: SCHOOL_USER_DISABLE
          },
          {
            title: 'adminMenu.administrator.admins.admins',
            url: ADMIN_SUPER_USERS,
            icon: 'group',
            permission: USER_ADMIN_VIEW
          },
          {
            title: 'adminMenu.administrator.admins.disable',
            url: ADMIN_SUPER_USERS_DISABLE,
            icon: 'person_off',
            permission: USER_ADMIN_DISABLE
          }
        ]
      },
      {
        title: 'adminMenu.administrator.companies',
        url: ADMIN_USER_COMPANY,
        icon: 'business',
        permission: COMPANY_VIEW
      },
      {
        title: 'adminMenu.administrator.roles',
        url: ADMIN_USER_ROLE,
        icon: 'admin_panel_settings',
        permission: ROLE_VIEW
      },
      {
        title: 'adminMenu.administrator.settings',
        url: ADMIN_SETTING,
        icon: 'settings',
        permission: SETTING
      },
      {
        title: 'Activity Logs',
        url: ADMIN_ACTIVITY_LOGS,
        icon: 'history',
        permission: ACTIVITY_LOG
      }
    ]
  }
]
