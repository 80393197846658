import { Chip, Grid, Paper, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import BreadCrumb from '~/components/BreadCrumb'
import { useActivityLogDetailByIdQuery } from '../query'
import { useTranslation } from 'react-i18next'
import LogInformation from './LogInformation'
import DataChangeTable from './DataChangeTable'
import { useMemo } from 'react'
import { getActionColor } from '~/utils/helpers'
import { ADMIN_ACTIVITY_LOGS } from '~/constants/Routes'

const localization = 'pages.overview.activities.'

const ActivityLogsDetails = () => {
  const { id } = useParams()
  const { t } = useTranslation()

  const breadCrumbConfig = [
    { title: 'Overview', route: ADMIN_ACTIVITY_LOGS },
    {
      title: 'Activity Logs',
      route: ADMIN_ACTIVITY_LOGS
    },
    {
      title: 'Log Detail',
      page: 'detail'
    }
  ]
  const { data, isLoading } = useActivityLogDetailByIdQuery({ id })

  const logInfors = [
    {
      key: t(`${localization}id`),
      value: data?.data?.id
    },
    {
      key: t(`${localization}action`),
      value: (
        <>
          <Chip
            label={data?.data?.causer_id ? 'API' : 'EVENT'}
            color={data?.data?.causer_id ? 'info' : 'secondary'}
            size='small'
            sx={{ marginRight: 1 }}
          />
          <Chip
            label={data?.data?.properties?.action.toString().toUpperCase()}
            color={getActionColor(data?.data?.properties?.action)}
            size='small'
          />
        </>
      )
    },

    {
      key: t(`${localization}description`),
      value: `${data?.data?.description} ${data?.data?.subject_type}`
    },
    {
      key: t(`${localization}model`),
      value: data?.data?.subject_type ?? ''
    },
    {
      key: t(`${localization}user`),
      value:
        data?.data?.name && data?.data?.user_id ? `${data.data.name} - ${data.data.user_id}` : 'N/A'
    },
    {
      key: t(`${localization}createdAt`),
      value: data?.data?.created_at
    }
  ]

  const dataChanges = useMemo(() => {
    const dataChanges = []
    if (data?.data?.properties?.new_data) {
      for (const [key, value] of Object.entries(data?.data?.properties?.new_data)) {
        dataChanges.push({
          key,
          new: value,
          original: data?.data?.properties?.original_data[`${key}`] ?? ''
        })
      }
    } else if (data?.data?.properties?.original_data) {
      for (const [key, value] of Object.entries(data?.data?.properties?.original_data)) {
        dataChanges.push({
          key,
          new: value,
          original: ''
        })
      }
    }

    return dataChanges
  }, [data?.data?.properties?.new_data, data?.data?.properties?.original_data])

  return (
    <>
      <BreadCrumb breadcrumbs={breadCrumbConfig} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            sx={{
              py: 1,
              px: 2,
              height: '100%'
            }}
          >
            <Typography variant='h6' gutterBottom>
              {t(`${localization}activityLog`)} {id}
            </Typography>

            <LogInformation logInfors={logInfors} isLoading={isLoading} />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper
            sx={{
              py: 1,
              px: 2,
              height: '100%'
            }}
          >
            <Typography variant='h6' gutterBottom>
              {t(`${localization}dataChanged`)}
            </Typography>

            <DataChangeTable
              data={dataChanges}
              isContainNewData={!!data?.data?.properties?.new_data}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default ActivityLogsDetails
